<template>
  <a-modal
        v-model:visible="show"
        centered
        closable
        title="Add Feature"
        :footer="null"
        destroyOnClose
      >
    <add-feature @toggleModal="(value) => (show = value)"></add-feature>
  </a-modal>
  <a-row class="p-4" :gutter="[16, 16]">
    <a-col span="24">
      <a-typography-title :level="5">Add Feature Subscription</a-typography-title>
    </a-col> 
    <a-col span="6">
      <a-select
        ref="select"
        v-model:value="organization_id"
        :options="orgOptions"
        placeholder="Select Organization"
        show-search
        class="w-100"
      ></a-select>
    </a-col>
    <a-col span="6">
      <a-select
        ref="select"
        v-model:value="feature_id"
        :options="featureOptions"
        placeholder="Select Feature"
        show-search
        class="w-100"
      ></a-select>
    </a-col>
    <a-col span="12">
      <a-button type="primary" @click="handleSubmit">Submit</a-button>
      <a-button
        class="float-right mb-3 d-flex align-items-center"
        type="primary"
        @click="show = !show"
        >
        <template #icon><i class="bi bi-plus mr-1 mb-1"></i></template>
        Add Feature
      </a-button>
    </a-col>
    <a-col span="24">
      <list-feature-subscription></list-feature-subscription>
      <list-feature></list-feature>
    </a-col>
  </a-row>
</template>
<script>
import AddFeature from './AddFeature.vue';
import ListFeatureSubscription from './ListFeatureSubscription.vue'
import ListFeature from './ListFeature.vue'
import { mapActions, mapGetters } from 'vuex';

export default {
  components: {
    AddFeature,
    ListFeatureSubscription,
    ListFeature
  },
  data() {
    return {
      show: false,
      feature_id: null,
      organization_id: null
    };
  },
  computed: {
    ...mapGetters(['features', 'organizations']),
    featureOptions(){
      return this.features.map(item => ({
        value: item.id,
        label: item.name
      }));
    },
    orgOptions(){
      return this.organizations.map(item => ({
        value: item.Org_name,
        label: item.Org_name,
      }));
    }
  },
  methods: {
    ...mapActions(['getAllFeatures', 'getOrganizations', 'addNewFeatureSubscription', 'getAllFeatureSubscriptions']),
    handleSubmit(){
      this.addNewFeatureSubscription({feature_id:this.feature_id, organization_id:this.organization_id})
    }
  },
  mounted() {
    this.getAllFeatures()
    this.getOrganizations()
    this.getAllFeatureSubscriptions()
  }
};
</script>
