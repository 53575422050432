<template>
  <a-form
    layout="vertical"
    :model="featureName"
    ref="formRef"
    name="add_feature"
    @finish="addFeature()"
  >
    <div class="row">
      <div class="col-sm-12">
        <a-form-item label="Enter Feature" name="featureName">
          <a-input placeholder="ex: REALTIME_SUBSCRIPTION" v-model:value="featureName"> </a-input>
        </a-form-item>
      </div>
    </div>
    <div class="buttons-w d-flex justify-content-end">
      <a-button type="primary" html-type="submit" :disabled="isFeatureExist">
        Create
      </a-button>
    </div>
  </a-form>
</template>

<script>
import { mapActions } from 'vuex';

export default {
  name: 'AddFeature',
  emits: ['toggleModal'],
  data() {
    return {
      featureName: '',
    };
  },
  computed: {
    isFeatureExist() {
      return this.featureName === '';
    },
  },
  methods: {
    ...mapActions(['addNewFeature']),
    addFeature() {
      this.addNewFeature({featureName: this.featureName});
      this.$emit('toggleModal', false);
    },
  },
};
</script>
